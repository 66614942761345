import BlogPosts from '../components/BlogPosts'
import NavBar from '../components/Navbar/NavBar'
import Footer from '../components/Footer'

export default function BlogPage() {
  return (
    <div className="flex min-h-screen flex-col">
      <NavBar />
      <main className="flex flex-col items-center justify-between p-24 flex-grow">
        <BlogPosts />
      </main>
      <Footer />
    </div>
  )
}
