import React from 'react'
import clinicbuilding from '../images/clinic-building.svg'
import smileplus from '../images/smileplus.svg'

const Services = () => {
  return (
    <div id="services" className="bg-gray-100 py-12">
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">services</h2>

          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
        </div>

        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-40 max-w-4xl m-auto">
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-full h-full"
                  src={clinicbuilding}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">Find Healthcare</h2>
                <p className="text-md font-medium">
                  Find quality care and know what you can expect to pay. Search by insurance,
                  facility, and problem and find information tailored to you.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-full h-full"
                  src={smileplus}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  Tell Us About Your Healthcare Experience
                </h2>
                <p className="text-md font-medium">
                  Provide feedback on your recent experiences at various healthcare facilities and
                  contribute to transparent healthcare pricing. Feedback is anonymous. By doing so
                  you will be helping others and helping lower costs for all.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
          <div
            className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
            data-aos="zoom-out"
          >
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center items-center">
              <div className="flex items-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className="fill-current text-blue-900 mr-4"
                >
                  <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                </svg>
                <h3 className="text-3xl text-blue-900 font-bold text-center">
                  <span className="font-black">Know Your Healthcare Costs and Quality</span>
                </h3>
              </div>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  We aggregate actual costs based on treatment and insurance to generate what your
                  expected out of pocket will be and the quality of your outcome will be at
                  facilities near you. While we can’t guarantee your results, our data is provided
                  by people just like you and their actual experiences.
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center items-center">
              <div className="flex items-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className="fill-current text-blue-900 mr-4"
                >
                  <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                </svg>
                <h3 className="text-3xl text-blue-900 font-bold text-center">
                  <span className="font-black">Your Privacy, Our Duty</span>
                </h3>
              </div>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  We know providing your information can be scary - thats why we don't store any
                  personally identifiable information. Information is anonymous from the moment you
                  provide us with it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Services
