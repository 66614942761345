import React from 'react'

export default function Pagination({ currentPage, totalPages, paginate }) {
  const renderPageNumbers = () => {
    const pageNumbers = []

    if (totalPages <= 5) {
      // Display all pages if there are 5 or fewer
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i)
      }
    } else {
      // Display a truncated set of page numbers
      const startPage = Math.max(2, currentPage - 1)
      const endPage = Math.min(totalPages - 1, currentPage + 1)

      pageNumbers.push(1) // Always show the first page
      if (startPage > 2) {
        pageNumbers.push('...') // Ellipsis if the gap between 1 and startPage is large
      }
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i)
      }
      if (endPage < totalPages - 1) {
        pageNumbers.push('...') // Ellipsis if the gap between endPage and totalPages is large
      }
      pageNumbers.push(totalPages) // Always show the last page
    }

    return pageNumbers.map((number, index) =>
      number === '...' ? (
        <span key={index} className="mx-1">
          ...
        </span>
      ) : (
        <button
          key={number}
          onClick={() => paginate(number)}
          className={`mx-1 px-3 py-1 border rounded ${
            currentPage === number ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'
          }`}
        >
          {number}
        </button>
      )
    )
  }

  return (
    <div className="flex justify-center mt-4">
      <button
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
        className="mx-1 px-3 py-1 border rounded bg-white text-blue-500"
      >
        Previous
      </button>
      {renderPageNumbers()}
      <button
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="mx-1 px-3 py-1 border rounded bg-white text-blue-500"
      >
        Next
      </button>
    </div>
  )
}
