import React from 'react'
//import Clients from '../components/Clients'
//import Cta from '../components/Cta'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import Intro from '../components/Intro'
import Team from '../components/Team'
import Services from '../components/Services'

const Home = () => {
  return (
    <>
      <Hero />
      <Intro />
      <Services />
      <Team />
      <Footer />
    </>
  )
}

export default Home
