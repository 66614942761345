import { useEffect, useState } from 'react'
import { request } from 'graphql-request'
import Pagination from '../components/Pagination' // Ensure the Pagination component is imported

const API_URL = 'https://blog.yourcopay.com/graphql'

const query = `
  query {
    posts(where: {categoryName: "blog"}) {
      nodes {
        id
        title
        date
        content
        categories {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default function BlogPosts() {
  const [posts, setPosts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const postsPerPage = 3

  useEffect(() => {
    request(API_URL, query)
      .then((data) => setPosts(data.posts.nodes))
      .catch((error) => console.error(error))
  }, [])

  // Calculate the current posts to display based on the current page
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost)

  // Determine the total number of pages
  const totalPages = Math.ceil(posts.length / postsPerPage)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  return (
    <>
      <div className="container mx-auto py-8">
        <div className="w-full max-w-5xl mx-auto">
          {currentPosts.map((post) => (
            <div key={post.id} className="mb-8 p-6 border rounded-lg shadow-lg bg-white">
              <h2 className="text-3xl font-bold mb-2 text-gray-800">{post.title}</h2>
              <p className="text-gray-500 text-sm mb-4">
                {new Date(post.date).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </p>
              <div
                className="text-gray-700 leading-relaxed"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />
            </div>
          ))}
        </div>

        {/* Restore Pagination */}
        <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
      </div>
    </>
  )
}
